<template>
    <div>
        <router-view />
    </div>
</template>

<script>
import config from '@/config'
import { setCookieForYear } from '@/utils/utils'
import { AppCallBackAction } from '@/managers/publicMethod'

import { useDataCenter } from '@/managers/dataCenter'

const dataCenter = useDataCenter()

export default {
    created() {
        if (config.isLocalDevelopment) {
            // 임시 사용자 정보데이터
            setCookieForYear(dataCenter.KEYS_SYSTEM.APP_TYPE, 'IOS')
            setCookieForYear(dataCenter.KEYS_SYSTEM.APP_VERSION, '2.4.06')
            // setCookieForYear('OSVersion', '1.0')
            setCookieForYear(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE, 'ko')
            setCookieForYear(dataCenter.KEYS_SYSTEM.COUNTRY_CODE, '82')
        }

        const urlParams = new URLSearchParams(window.location.search)
        let appType = urlParams.get('appType')
        if (appType !== undefined && appType !== null)
            setCookieForYear(dataCenter.KEYS_SYSTEM.APP_TYPE, appType)
        let appVersion = urlParams.get('appVersion')
        if (appVersion !== undefined && appVersion !== null)
            setCookieForYear(dataCenter.KEYS_SYSTEM.APP_VERSION, appVersion)
        // let osVersion = urlParams.get('osVersion')
        // if (osVersion !== undefined && osVersion !== null)
        //     setCookieForYear('OSVersion', osVersion)
        let languageCode = urlParams.get('languageCode')
        if (languageCode !== undefined && languageCode !== null)
            setCookieForYear(dataCenter.KEYS_SYSTEM.LANGUAGE_CODE, languageCode)
        let countryCode = urlParams.get('countryCode')
        if (countryCode !== undefined && countryCode !== null)
            setCookieForYear(dataCenter.KEYS_SYSTEM.COUNTRY_CODE, countryCode)
        let utcInterval = urlParams.get('utcInterval')
        if (utcInterval !== undefined && utcInterval !== null)
            setCookieForYear(dataCenter.KEYS_SYSTEM.UTC_INTERVAL, utcInterval)
        let logCode = urlParams.get('logCode')
        if (logCode !== undefined && logCode !== null) {
            if (logCode === 'null') {
                logCode = null
            }
            setCookieForYear(dataCenter.KEYS_SYSTEM.LOG_CODE, logCode)
        }

        // 추후 다국어 처리시 아래 코드 활용 일단 한글 강제 적용
        // this.$i18n.locale =
        //     languageCode === undefined || languageCode === null || languageCode === ''
        //         ? 'ko'
        //         : languageCode
        this.$i18n.locale = 'ko'
    },
    mounted() {
        window.appCallMoveToBack = AppCallBackAction
        console.log('')
    },
}
</script>

<style>
@import './assets/css/normalization.css';
@import '@/assets/css/common.css';
@import '@/assets/css/iconimage.css';

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
