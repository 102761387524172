import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { i18n } from './config/i18n'
import VueGtag from 'vue-gtag'
import config from '@/config'
import { createPinia } from 'pinia'

if (config.buildMode === 'dev') {
    console.log('buildMode DEV')
    createApp(App)
    .use(router)
    .use(createPinia())
    .use(i18n)
    .mount('#app')
} else {
    console.log('buildMode Prod')
    createApp(App)
    .use(router)
    .use(createPinia())
    .use(i18n)
    .use(VueGtag, {
            config: {
                id: 'G-P2JPHJB4FZ',  // Google Analytics의 Tracking ID를 넣어준다
            }
        },
        router
    ).mount('#app')
}
