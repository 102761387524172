import { historyPage } from '@/utils/utils'

// 안드로이드 뒤로가기 버튼 액션
export function AppCallBackAction() {
    historyPage()
}

// 웹뷰 강제종료 이벤트 (모바일에서 웹뷰가 강제로 종료됐을 때 상황처리)
let handlerWebViewForceClosed = null
export function SetHandlerWebViewForceClosed(handler) {
    handlerWebViewForceClosed = handler
}
export function WebViewForceClosed() {
    if (handlerWebViewForceClosed) {
        handlerWebViewForceClosed()
    }
}
