import { createI18n } from 'vue-i18n'
import ko from '@/assets/language/ko.json'
import en from '@/assets/language/en.json'

export const i18n = createI18n({
    legacy: false,
    locales: 'ko',
    fallbacklocale: 'en',
    messages: {
        'ko': ko,
        'en': en
    }
})