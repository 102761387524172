import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/MainDashboard',
    name: 'MainDashboard',
    component: () => import('../components/views/dashboard/MainDashboardArea.vue')
  },
  {
    path: '/DashboardPage',
    name: 'DashboardPage',
    component: () => import('../components/views/dashboard/DashboardPage.vue')
  },
  {
    path: '/ReportPage',
    name: 'ReportPage',
    component: () => import('../components/views/report/ReportPage.vue')
  },
  {
    path: '/MissionPhotosPage',
    name: 'MissionPhotosPage',
    component: () => import('../components/views/photos/MissionPhotosPage.vue')
  },
  {
    path: '/DetailMissionPage',
    name: 'DetailMissionPage',
    component: () => import('../components/views/photos/DetailMissionPage.vue')
  },
  {
    path: '/Chart',
    name: 'Chart',
    component: () => import('../components/views/chart/InBodyChangeGraph.vue')
  },
  {
    path: '/MissionPhotosPage',
    name: 'MissionPhotosPage',
    component: () => import('../components/views/photos/MissionPhotosPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 여기에서 스크롤 동작을 정의
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

export default router
